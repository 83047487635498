import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16')
];

export const server_loads = [0];

export const dictionary = {
		"/": [2],
		"/clubs": [~4],
		"/club/[slug]": [~3],
		"/feedback": [5],
		"/food": [~6],
		"/get-the-app": [7],
		"/interests": [~8],
		"/links": [9],
		"/map": [~10],
		"/offline": [11],
		"/privacy-policy": [12],
		"/scavenger-hunt": [13],
		"/schedule": [~14],
		"/settings": [15],
		"/sponsors": [~16]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';